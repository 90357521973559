/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import dynamic from 'next/dynamic';
import React from 'react';
// import { WeddingCover } from './1/WeddingCover';

const ImageSection = dynamic(() => import('./v1'));
const ImageSection2 = dynamic(() => import('./v2'));
const ImageSection3 = dynamic(() => import('./v3'));

// eslint-disable-next-line import/prefer-default-export
export default function GallerySection({
  data,
  sectionData,
}: {
  data: any;
  sectionData: any;
}) {
  const { layoutType } = sectionData;
  return (
    <div className="w-full">
      {(!layoutType || layoutType === 'v1') && (
        <ImageSection data={data} sectionData={sectionData} />
      )}
      {layoutType === 'v2' && (
        <ImageSection2 data={data} sectionData={sectionData} />
      )}
      {layoutType === 'v3' && (
        <ImageSection3 data={data} sectionData={sectionData} />
      )}
    </div>
  );
}
