/* eslint-disable no-underscore-dangle */
import React from 'react';

import BookingDateSection from './section/BookingDate';
import FaqSection from './section/Faq';
import FeatureSection from './section/FeatureSection';
import GallerySection from './section/GallerySection';
import HeroSection from './section/HeroSection';
import ReviewSection from './section/ReviewSection';
import SellingPointSection from './section/SellingPoint';

const BrandSection = ({ data, brand }: { data: any; brand?: any }) => {
  // const router = useRouter();
  // console.log('data: ', data);

  const sectionData = data
    ?.filter((section: { isActive: boolean }) => section.isActive === true)
    ?.map(
      (section: { __component: any }, index: React.Key | null | undefined) => {
        switch (section.__component) {
          case 'brand-section.gallery':
            return (
              <GallerySection data={data} sectionData={section} key={index} />
            );
          case 'brand-section.hero':
            return (
              <HeroSection data={data} sectionData={section} key={index} />
            );
          case 'brand-section.feature':
            return (
              <FeatureSection data={data} sectionData={section} key={index} />
            );
          case 'brand-section.review':
            return (
              <ReviewSection data={data} sectionData={section} key={index} />
            );
          case 'brand-section.selling-point':
            return (
              <SellingPointSection
                data={data}
                sectionData={section}
                key={index}
              />
            );
          case 'brand-section.faq':
            return <FaqSection data={data} sectionData={section} key={index} />;
          case 'brand-section.unavailable-booking-dates':
            return (
              <BookingDateSection
                data={data}
                sectionData={section}
                brand={brand}
                key={index}
              />
            );

          default:
            return null;
        }
      },
    );

  return (
    <div className="flex w-full flex-col gap-12">
      {sectionData?.map((section: any) => {
        return section;
      })}
    </div>
  );
};

export default BrandSection;
