/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable */
import { Button, Text, Tooltip } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";

// import User from < i className="ri-user-line"></i>;
// eslint-disable-next-line import/extensions
// import { domain } from '../../utils/AppConfig';

interface NavbarProps {
  data: any;
  // data: {
  //   logo: {
  //     url: string;
  //   };
  //   name: string;
  //   isContributor: boolean;
  //   domain: string;
  //   whatsAppNumber: string | null;
  // };
}

function Navbar({ data }: NavbarProps) {
  const logoBahagiaDay = "/assets/images/logobahagiaday.svg";
  const router = useRouter();
  const showThemeNavigation = data?.showYourThemes || data?.showOfficialThemes;

  // const mainDomains = ['official.bahagia.day', 'official-dev.bahagia.day'];
  const mainDomains = ["official.bahagia.day"];

  const mainDomain = mainDomains.includes(data.domain) ? data.domain : null;

  const [openTooltip, setOpenTooltip] = useState(false);
  const closeTooltipAfterDelay = () => {
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  // const [opened, { open, close }] = useDisclosure(false);
  const navLink = [
    {
      href: "https://app.bahagia.day",
      title: "Yuk daftarin brand kamu di bahagia.day",
      isActive: data.domain === mainDomain,
    },
    { href: "/", title: "Home", isActive: data.domain !== mainDomain },
    {
      href: "/katalog",
      title: "Katalog",
      isActive: data.domain !== mainDomain,
    },
    {
      href: "/tema",
      title: "Tema",
      isActive: data.domain !== mainDomain && showThemeNavigation,
    },
    {
      href: "/generator",
      title: "Generator",
      isActive: data.domain !== mainDomain && showThemeNavigation,
    },
    // { href: '/customer', title: 'Customer' },
    // { href: '/promo', title: 'Promo' },
  ];

  if (data.domain !== mainDomain && data?.home?.length > 0) {
    // if (data?.home?.length === 0) {
    // Add the additional navigation item
    navLink.push({ href: "/link", title: "Link", isActive: true });
  }

  return (
    <div className="fixed top-0 z-10 flex w-full justify-center">
      <div className=" w-[500px] bg-white">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-[10px]">
            <Image
              src={data.logo ? data.logo.url : logoBahagiaDay}
              className="aspect-square rounded-full object-cover"
              alt="logo"
              width={32}
              height={32}
            />
            <div className="flex shrink-0 grow-0 flex-col items-start justify-center gap-[2px] self-stretch">
              <div className="flex shrink-0 grow-0 items-center justify-center gap-[4px]">
                <p className="shrink-0 grow-0 text-left text-[15px] font-semibold leading-[19.5px]  text-[#293443]">
                  {data.name}
                </p>
                {data.isContributor && (
                  <Tooltip
                    withArrow
                    label="Official Brand Partner"
                    opened={openTooltip}
                  >
                    <Image
                      onClick={() => {
                        setOpenTooltip(true); // Buka kembali tooltip
                        closeTooltipAfterDelay(); // Tutup tooltip setelah 500ms
                      }}
                      src="/assets/icons/ic-verified.svg"
                      className="aspect-square rounded-full object-cover"
                      alt="logo"
                      style={{ width: "16px", height: "16px" }}
                      width={24}
                      height={24}
                    />
                  </Tooltip>
                )}
              </div>
              <p className="shrink-0 grow-0 text-left text-[10px] text-[#38465b]">
                {data.domain}
              </p>
            </div>
          </div>
          <Button
            component="a"
            href={
              data.domain === mainDomain
                ? "https://app.bahagia.day"
                : data?.whatsAppNumber
                ? `https://wa.me/${data?.whatsAppNumber}`
                : ""
            }
            target="_blank"
            rel="noopener noreferrer"
            className="h-[28px] w-auto rounded-md text-[#38465B] bg-[#EFEFEF] px-2 hover:bg-[#DBDBDB]"
          >
            <Text>{data.domain === mainDomain ? "Daftar / Masuk" : "Chat Admin"}</Text>
          </Button>
        </div>

        <div className="px-4">
          <div className="flex items-center justify-center gap-4 border-y border-[#ede8e2] py-[12px] leading-[16.5px]">
            {navLink
              .filter((nav) => nav.isActive)
              .map((nav, index) => (
                <Link
                  href={nav.href}
                  key={index}
                  className={`text-[13px]  ${
                    // eslint-disable-next-line no-nested-ternary
                    router.pathname === nav.href
                      ? "font-bold text-[#293443]"
                      : nav.href === "https://app.bahagia.day"
                      ? // ? "font-bold text-[#229E5B]"
                        "font-medium text-[#38465B]"
                      : "font-normal text-[#38465B]"
                  }`}
                >
                  {nav.title}
                </Link>
              ))}
          </div>
        </div>
      </div>
      {/* <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton={false}
        size={'auto'}
        padding={'50px'}
      >
        Customer Area Cooming Soon
      </Modal> */}
    </div>
  );
}

export default Navbar;
