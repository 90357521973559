/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import dynamic from 'next/dynamic';
import React from 'react';
// import { WeddingCover } from './1/WeddingCover';

const HeroSectionContent = dynamic(() => import('./v1'));

// eslint-disable-next-line import/prefer-default-export
export default function FeatureSection({
  data,
  sectionData,
}: {
  data: any;
  sectionData: any;
}) {
  const { layoutType } = sectionData;
  return (
    <>
      {(!layoutType || layoutType === 'v1') && (
        <HeroSectionContent data={data} sectionData={sectionData} />
      )}
    </>
  );
}
