/* eslint-disable unused-imports/no-unused-vars */
import React from 'react';

function SellingPointSectionContent({
  sectionData,
  data,
}: {
  data: any;
  sectionData: any;
}) {
  return (
    <div className="columns-2 gap-4 space-y-4 px-4">
      {sectionData?.content?.map((item: any, i: any) => (
        <div
          key={i}
          className="grid h-auto max-w-full break-inside-avoid gap-1 rounded-xl bg-[#f2f2f2] px-4 py-3 sm:w-full"
        >
          <div className="relative flex shrink-0 grow-0 flex-col items-start justify-center gap-1 self-stretch">
            <p className="text-md w-full shrink-0 grow-0 self-stretch whitespace-normal text-left font-bold text-[#38465b]">
              {item.title}
            </p>
            <p className="w-full shrink-0 grow-0 self-stretch whitespace-normal text-left text-sm text-[#7c7d8a]">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SellingPointSectionContent;
