/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import React from 'react';

import SellingPointSectionContent from './v1';
// import { WeddingCover } from './1/WeddingCover';

// eslint-disable-next-line import/prefer-default-export
export default function SellingPointSection({
  data,
  sectionData,
}: {
  data: any;
  sectionData: any;
}) {
  const { layoutType } = sectionData;
  return (
    <>
      {(!layoutType || layoutType === 'v1') && (
        <SellingPointSectionContent data={data} sectionData={sectionData} />
      )}
    </>
  );
}
