/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import React from 'react';

import BookingDateSectionContent from './v1';

// import { WeddingCover } from './1/WeddingCover';

// eslint-disable-next-line import/prefer-default-export
export default function BookingDateSection({
  data,
  sectionData,
  brand,
}: {
  data: any;
  sectionData: any;
  brand?: any;
}) {
  const { layoutType } = sectionData;
  return (
    <>
      {(!layoutType || layoutType === 'v1') && (
        <BookingDateSectionContent
          data={data}
          sectionData={sectionData}
          brand={brand}
        />
      )}
    </>
  );
}
